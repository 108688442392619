.about-content {
    font-family: "Montserrat", system-ui;
    font-optical-sizing: auto;
    font-weight: 14px;
    font-style: normal;
    color: black;
    text-align: justify;
}
    .about-image{
        width: 100%;
    }