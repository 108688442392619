/* 
  .footer {
    background-color: #292A5B;
    color: #dcdcdc;
  }
 .icon-text {
    text-decoration: none;
    color: #dcdcdc;
 }
 .footer-div {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding: 15px;
    background-color: #dcdcdc;
    color: #292A5B;
  }
  .footer-span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
  }
  
  @media(min-width:992px) {
   
  } */



  
.footer-bg {
        background-color: #dcdcdc ;
        color: #292A5B;
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px 10px;
  }
  .footer-home,
  .footer-follow,
  .footer-meetus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .footer-home {
    gap: 13px;
  }
  .footer-follow,
  .footer-meetus {
    gap: 16px;
  }
  .footer-icon-link {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .icons {
    width: 17.5px;
    height: 17.5px;
  
  }
  .map {
    width: 100%;
    height: 232.11px;
  }
  .footer-Nav {
    font-family: Manrope;
    font-weight: 400;
    font-size: 16px;
   
    text-decoration: none;
  }
  .follow-text, .follow-home {
    color: #292A5B;
    font-family: Salsa;
    font-size: 26px;
    font-weight: 600;
    position: relative;
    margin-bottom: 7px;
  }
  .follow-home::after {
    content: "";
    width: 30%;
    height: .1em;
    background-color: #dcdcdc;
    position: absolute;
    bottom: -2px;
    left: 0;
  
  }
  .follow-text::after {
    content: "";
    width: 20%;
    height: .1em;
    background-color: #dcdcdc;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
  .icon-text {
    font-family: poppins;
    font-weight: 400;
    font-size: 16px;
    color: #292A5B;
    text-decoration: none;
  }
  .icon-text:hover {
    font-weight: 500;
  }
  
  .footer-map {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .footer-span {
    padding: 23px 0px;
    border-top:1px solid white;
    margin: 0;
    color: #292A5B;
    font-size: 13px;
    font-weight: 600;
    font-family: poppins;
  }
  .footer-span-link {
    margin-left: 5px;
    text-decoration: none;
    color:#292A5B;
    font-size: 13px;
    font-weight: 600;
    font-family: poppins;
  }
  .logo-img{
    width: 50%;

  }
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 662px) {
    .footer-follow,
  .footer-meetus {
    gap: 21px;
  }
    .footer-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 33px 0px;
    }
    .footer-home,
    .footer-follow,
    .footer-meetus {
      justify-content: center;
      align-items: start;
    }
    .map {
      width: 180px;
      height: 132.11px;
    }
  }
  @media (min-width: 992px) {
    .footer-follow,
  .footer-meetus {
    gap: 21px;
    justify-content: center;
  }
  .footer-container {
    display: flex;
    flex-direction: row;
    padding: 40px 0px;
    gap: 20px;
  }
    .footer-home,
    .footer-follow,
    .footer-meetus {
      justify-content: center;
      align-items: start;
      padding: 0px 10px;
    }
   
  }