.sectionTitle{
    
    font-family: "Salsa", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 46px;
    color: black;
    padding-top: 96px;
    padding-bottom: 68px;
}



.card {
    padding: 0px !important;
    box-shadow: 0 3px 10px #0003;
    height: 830px !important;
    border-radius: none !important;
}

    
.service-name{

    font-family: "Salsa", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 26px;
    color: black;
    padding-top: 28px;
    padding-bottom: 28px;
}

.service-desc{
    font-family: "Montserrat", system-ui;
    font-optical-sizing: auto;
    font-weight: 14px;
    font-style: normal;
    color: black;
    text-align: justify;
}
.service-image {
    height: 329px !important;
   
}
@media(min-width:992px) {
    
.card {
    height: 713px !important;
}

}