.banner{
    position: relative;
}

.banner img{
    width: 100%;
    height: Auto;
    object-fit: cover;
}

.hero-text{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: white;
    font-family: "Salsa", cursive;
    font-weight: 400;
    font-style: normal;
    width: 70%;
}


@media (min-width: 992px) {
    .hero-text{
        font-size: 46px;
    }
}